<template>
	<a-modal v-model="visible" :title="false" width="50%" :footer="null" @cancel="_close" centered destroyOnClose>
		<a-page-header bordered :ghost="false" :title="data.title">
			<a-descriptions size="small" :column="3">
				<a-descriptions-item label="分类">
					{{ data.categoryName }}
				</a-descriptions-item>
				<a-descriptions-item label="可获得积分">
					{{ data.grade }}
				</a-descriptions-item>
				<a-descriptions-item label="描述">
					{{ data.description }}
				</a-descriptions-item>
			</a-descriptions>
			<template slot="footer">
				<div class="content">
					<div style="padding: 10px 0;border-top: 1px solid #f7f7f7;" v-html="data.content"></div>
				</div>
			</template>
		</a-page-header>
	</a-modal>
</template>

<script>
import request from '@/config/request';
import utils from '@/common/utils';
import apiUtil from '@/common/apiUtil';
export default {
	data() {
		return {
			visible: false,
			loading: false,
			time: '',
			id: '',
			inter: null,
			data: {
				title: '',
				content: ''
			}
		};
	},
	methods: {
		...apiUtil,
		show(id) {
			utils.showSpin();

			console.log(id);
			this.id = id;
			request.get('/platform/study/detail/' + id, {}).then(ret => {
				this.data = ret.data;
				this.time = ret.data.time;

				if (this.time != 0) {
					this.settiem();
				}
				this.visible = true;
			});
		},
		_close() {
			clearInterval(this.inter);
			this.visible = false;
		},
		// 设置倒计时
		settiem() {
			this.inter = setInterval(() => {
				this.time--;
			}, 1000);
		}
	},
	watch: {
		time(newv) {
			if (newv == 0) {
				clearInterval(this.inter);
				request.put('/platform/study/read/' + this.id, {}).then(ret => {
					if (ret.code == 200) {
						if (ret.data != 0) {
							this.utils.success(`已获得${this.data.grade}积分}`).then(() => { });
						}
					}
				});
			}
		}
	}
};
</script>
<style>
.content img {
	max-width: 100% !important;
}

.countdown {
	width: 30px;
	height: 30px;
	border: 1px solid blue;
	border-radius: 50%;
	text-align: center;
}

.time {
	line-height: 30px;
}
</style>
